video._styles-module__video__2WNzJ {
  -o-object-position: center;
     object-position: center;
  border-radius: 4px;
  min-height: 0;
  min-width: 0;
}

._styles-module__participant__34YL4 {
  box-sizing: border-box;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  border: 2px solid rgba(0, 0, 0, 0);
}

._styles-module__speaker__1y2fW {
  border: 2px solid #28994e;
}

._styles-module__placeholder__30KSQ {
  background: #c4c4c4;
  width: 100%;
  height: 100%;
}

._styles-module__participantBar__2LCHl {
  box-sizing: border-box;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 6px 8px 6px 8px;
  font-weight: 500;
  font-size: 12px;
  display: grid;
  gap: 8px;
  grid-template-columns: auto 1fr -webkit-min-content -webkit-min-content;
  grid-template-columns: auto 1fr min-content min-content;
  justify-content: space-evenly;
  align-items: center;
}

._styles-module__participantBar__2LCHl ._styles-module__name__55u8f {
  white-space: nowrap;
  justify-self: start;
}

._styles-module__participantBar__2LCHl ._styles-module__center__213aF {
  justify-self: center;
}

._styles-module__participantBar__2LCHl ._styles-module__stats__te685 {
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

._styles-module__screenShare__1FIqv {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

._styles-module__iconRed__3LzZj {
  color: #981010;
}

._styles-module__controlsWrapper__2kKks {
}

._styles-module__buttonWrapper__3w3nM {
  display: inline-block;
  margin: 4px;
  white-space: nowrap;
}

._styles-module__button__hRq5k {
  display: inline-block;
  border-radius: 44px;
  border: none;
  background: #2f2f2f;
  padding: 12px 20px 12px 20px;
  cursor: pointer;
  color: white;
  font-weight: normal;
  font-size: 14px;
  white-space: nowrap;
}

._styles-module__button__hRq5k:active {
  border: none;
}

._styles-module__button__hRq5k:hover {
  background: #434343;
}

._styles-module__button__hRq5k:disabled {
  opacity: 0.5;
  cursor: default;
}

._styles-module__button__hRq5k ._styles-module__icon__1Nxni {
  display: inline-block;
  margin-right: 8px;
}

button._styles-module__dangerButton__Rt0RH {
  background: #981010;
}

button._styles-module__dangerButton__Rt0RH:hover {
  background: #b81212;
}

button._styles-module__dropdown__3Lz0y {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-left: 12px;
  padding-right: 15px;
  margin-left: 0;
}

button._styles-module__hasDropdown__3Bgvz {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 12px;
  margin-right: 0;
}

._styles-module__separator__1VLMM {
  display: inline-block;
  margin-top: -5px;
  margin-bottom: -5px;
  margin-right: 8px;
  margin-left: -12px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  width: 1px;
  height: 20px;
}

._styles-module__popoverMenu__3XXS4 {
  background: #2f2f2f;
  border-radius: 4px;
  margin-bottom: 5px;
}

ul._styles-module__list__dKNN7 {
  border: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  padding-inline: 0;
}

ul._styles-module__list__dKNN7 li {
  font-size: 14px;
  padding: 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

ul._styles-module__list__dKNN7 li:hover {
  background: #434343;
}

ul._styles-module__list__dKNN7 li:first-child {
  border: 0;
}

._styles-module__container__14898 {
  width: 100%;
  height: 100%;
  position: relative;
}

._styles-module__overlay__17KXW {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

._styles-module__unmuteButton__3QhLc {
  display: block;
  background-color: #5a8bff;
  cursor: pointer;
  padding: 12px 20px;
  border: none;
  color: white;
  font-weight: 700;
  filter: drop-shadow(3px 7px 13px rgba(0, 0, 0, 0.17));
  border-radius: 8px;
  text-transform: uppercase;
}

._styles-module__unmuteButton__3QhLc:active {
  border: none;
}

._styles-module__unmuteButton__3QhLc ._styles-module__icon__1Nxni {
  display: inline-block;
  margin-right: 12px;
}

._styles-module__container__2sm4e {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto -webkit-min-content;
  grid-template-rows: auto min-content;
  min-height: 0;
}

._styles-module__stage__2VE66 {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 100%;
  gap: 8px;
  overflow: hidden;
}

._styles-module__stageCenter__2kKDi {
  grid-column: 1 / 11;
}

._styles-module__sidebar__gA0MQ {
  grid-column: 11 / 13;
  display: grid;
  gap: 8px;
  grid-auto-rows: -webkit-min-content;
  grid-auto-rows: min-content;
  overflow-y: scroll;
}

._styles-module__controlsArea__1smc2 {
  height: 100px;
  display: grid;
  align-items: center;
  justify-items: center;
}

._styles-module__gridStage__2HbZ0 {
  display: grid;
  gap: 8px;
  overflow: hidden;
  height: 100%;
  align-items: center;
  justify-items: center;
}

._styles-module__grid1x1__2ltyS {
  grid-template-columns: auto;
  grid-template-rows: auto;
}

._styles-module__grid2x1__2vumQ {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
}

._styles-module__grid2x2__X-1Rk {
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
}

._styles-module__grid3x3__3rLR0 {
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

._styles-module__grid4x4__3qF9s {
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
}

._styles-module__grid5x5__mDdNR {
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
}

._styles-module__container__2zM8F {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto -webkit-min-content -webkit-min-content;
  grid-template-rows: auto min-content min-content;
  min-height: 0;
  row-gap: 16px;
}

._styles-module__stage__9PXrI {
  display: grid;
  overflow: hidden;
}

._styles-module__participantsArea__Tl5ht {
  display: flex;
  flex-direction: row;
  -moz-column-gap: 8px;
       column-gap: 8px;
  overflow-x: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

._styles-module__participantsArea__Tl5ht::-webkit-scrollbar {
  width: 0;
  height: 0;
}

._styles-module__participant__jGXiG {
  flex-shrink: 0;
  width: 180px;
}

._styles-module__controlsArea__3MB5b {
  height: 50px;
  display: grid;
  align-items: center;
  justify-items: center;
}
